import useCatchLog from "../hooks/useCatchLog";
import { LogEvent } from "../logger-event";

const clientLog = useCatchLog();
class PostExporter {
  // define member variables
  ids: string[];
  exportType = "Pdf";
  redirectUrl: string;

  // define constructor
  constructor(urlStr: string) {
    // convert url strin to URL
    const url = new URL(urlStr);

    // get the ids and export type from the url
    this.ids = url.searchParams?.getAll("exportIds");

    // delete the ids to get the redirect url
    url.searchParams.delete("exportIds");
    url.searchParams.delete("exportType");

    // set the protocol to https
    url.protocol = "https";

    // set the redirect url
    this.redirectUrl = url.toString();

    // set function
    this.exportIfApplicable = this.exportIfApplicable.bind(this);
  }

  // export the posts
  exportIfApplicable = async (): Promise<void> => {
    const backendURI = ENV.PUBLIC_NET_QS1_URI;

    if (this.ids?.length) {
      clientLog(
        {},
        {
          type: LogEvent.ExportPosts,
          ids: this.ids,
          exportType: this.exportType,
          redirectUrl: this.redirectUrl,
        },
      );
      // Update the URL to remove the export query params. This
      // prevents the export from being triggered again if the
      // user refreshes the page.
      window.history.pushState({}, "", this.redirectUrl);
      // Download the document from the API endpoint and trigger
      // the download of the file in the same window. This does not
      // trigger pop-up blockers.
      try {
        const response = await fetch(`${backendURI}/api/export${this.exportType}?postIds=${this.ids}`);
        const body = await response.json();
        const a = document.createElement("a");
        a.href = body.url;
        a.download = `export.${this.exportType}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (err) {
        useCatchLog(
          { data: `Failed to export posts: ${err}` },
          {
            type: LogEvent.ExportPostsFailure,
            ids: this.ids,
            exportType: this.exportType,
            redirectUrl: this.redirectUrl,
          },
        );
      }
    }
  };
}

export default PostExporter;
